<template>
    <div class="diy h100">
        <a-row class="h100" :gutter="10">
            <a-col :xxl="18" :xl="18" :lg="18" class="goods_list h100" style="background: #f9f9f9">
                <div class="flex_layout">
                    <div class="flex_top">
                         <a-steps :current="current">
                            <a-step v-for="(step, idx) in steps" :key="idx">
                                <template #title>{{step.title}}</template>
                                <template #description>
                                    <span>{{step.sub_title}}</span>
                                </template>
                            </a-step>
                        </a-steps>
                    </div>
                    <div class="flex_body">
                        <div class="flex_layout_row">
                            <div class="flex_left">
                                <div class="card h100">
                                    <a-spin :spinning="spinning">
                                        <div class="brand h100">
                                            <div class="brand_title">Brand</div>
                                            <div class="brand_list">
                                                <a-empty v-if="brandList.length == 0" />
                                                <div  v-else class="brand_cell" v-for="(brand, idx) in brandList" :key="idx">
                                                    <img @click="changeBrand(brand.brand_id)" :src="brand.logo" :class="['brand_logo', {on : formData.brand_id == brand.brand_id}]">
                                                </div>
                                            </div>
                                        </div>
                                    </a-spin>
                                </div>
                            </div>
                            <div class="flex_body">
                                <div class="goods_main h100">
                                    <perfect-scrollbar style="height: 100%"> 
                                    <div class="product_list">
                                        <a-spin :spinning="pro_spinning">
                                            <a-empty v-if="productList.length == 0" />
                                            <div v-else class="product_cell" @click="toProduct(product.product_id)" v-for="(product, idx) in productList" :key="idx">
                                                <div class="product_image">
                                                    <img class="image" :src="product.image" />
                                                </div>
                                                <div class="product_info flex_column">
                                                    <div class="product_top flex">
                                                        <div class="product_name">{{product.product_name}}</div>
                                                        <div class="product_price">${{product.product_price}}</div>
                                                    </div>
                                                    <div class="product_description">
                                                        <perfect-scrollbar style="height: 60px"> 
                                                        <div v-html="product.product_description" />
                                                        </perfect-scrollbar > 
                                                    </div>
                                                    <a-button @click.native.stop="add(product)" type="primary" class="btn primary" size="small">Add to cart</a-button>
                                                </div>
                                            </div>
                                        </a-spin>
                                    </div>
                                    </perfect-scrollbar> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex_bottom">
                        <div class="goods_bottom">
                            <div class="pagination">
                                <a-pagination size="small" v-model:current="formData.page" :total="total" show-less-items />
                            </div>
                            <div class="action">
                                <a-space>
                                    <a-button @click="prev" v-if="current > 0">Prev</a-button>
                                    <a-button type="primary" v-if="current < 2" @click="next">Next</a-button>
                                </a-space>
                            </div>
                        </div>
                    </div>
                </div>
            </a-col>
            <a-col :xxl="6" :xl="6" :lg="6" class="cart_list h100">
                <div class="flex_layout">
                    <div class="flex_top">
                        <div class="cart_top">
                            <span class="cart_title">Current order</span>
                            <span class="cart_price">Total price: <strong>${{totalPrice}}</strong></span>
                        </div>
                    </div>
                    <div class="flex_body">
                        <a-empty v-if="cartList.length == 0" />
                        <div class="cart_data h100" style="overflow: auto" v-else>
                            <div class="product_cell" v-for="(product, idx) in cartList" :key="idx">
                                <div class="product_image">
                                    <img class="image" :src="product.image" />
                                </div>
                                <div class="product_info flex_column">
                                    <div class="product_top flex">
                                        <div class="product_name">{{product.product_name}}</div>
                                        <div class="product_price">${{product.product_price}}</div>
                                    </div>
                                    <div class="product_top flex">
                                        <a-input-number @change="nums => changeNums(idx, nums)" size="small" :step="1" v-model:value="product.nums" />
                                        <a-button @click="remove(idx)" type="primary" style="width: 80px" danger size="small">Delete</a-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex_bottom">
                        <div class="cart_bottom">
                            <a-button class="primary btn" @click="goOrder" type="primary" block>Order</a-button>
                        </div>
                    </div>
                </div>
            </a-col>
        </a-row>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { getProductClassList } from '@/apis/home/productClass'
import { getBrandList } from '@/apis/home/brand'
import { getProductList } from '@/apis/home/product'
import storage from '@/utils/storage'
export default {
    name: 'DiyStore',
    data(){
        return {
            total: 0,
            cartList: [],
            current: 0,
            steps: [
                {title: 'One Step', sub_title: 'Choose my solar panel', id: 1},
                {title: 'Two Step', sub_title: 'Choose my inverter',  id: 2},
                {title: 'Three Step', sub_title: 'Choose my battery',  id: 3}
            ],
            total: 1,
            formData: {
                page: 1,
                brand_id: 1,
                class_id: 1
            },
            brandList: [],
            productList: [],
            spinning: false,
            pro_spinning: false
        }
    },
    methods: {
        next(){
            this.current++
            this.formData.class_id = this.steps[this.current].id
            this.getBrandListHandler()
            if(this.current == 2) return
        },
        prev(){
            this.current--
            this.formData.class_id = this.steps[this.current].id
            this.getBrandListHandler()
            if(this.current == 0) return
        },
        /**
         * 切换品牌
         */
        changeBrand(brand_id){
            this.formData.brand_id = brand_id
             this.getProductListHandler()
        },
        /**
         * 添加产品
         */
        add(product){
            const isExist = this.cartList.findIndex(r => r.product_id == product.product_id)
            if(isExist == -1){
                product.nums = 1
                this.cartList.push(product)
            }else{
                this.cartList[isExist].nums++
            }
            this.setCartList()
        },
        /**
         * 删除产品
         */
        remove(index){
            this.cartList.splice(index, 1)
           this.setCartList()
        },
        /**
         * 下单
         */
        goOrder(){
            //检查订单
            if(this.cartList.length == 0) return message.error('Please add a product')
            this.$router.push({path: '/order', query: {
                cost: this.totalPrice,
                source: 1
            }})
        },
        async getProductClassListHandler(){
            const res = await getProductClassList()
            res.list.forEach((item, idx) => {
                if(this.steps[idx]){
                    this.steps[idx].id = item.class_id
                }
            })
            this.formData.class_id = this.steps[0].id
            this.getBrandListHandler()
        },
        async getBrandListHandler(){
            this.productList = []
            this.spinning = true
            const res = await getBrandList({class_id: this.formData.class_id})
            this.spinning = false
            if(res){
                this.brandList = res.list
                if(res.list.length > 0){
                    this.formData.brand_id = res.list[0].brand_id
                    this.getProductListHandler()
                }
            }
        },
        async getProductListHandler(){
            this.pro_spinning = true
            const res = await getProductList({brand_id: this.formData.brand_id})
            this.pro_spinning = false
            if(res){
                this.productList = res.list.rows
                this.total = res.list.count
            }
        },
        toProduct(product_id){
            const { href } = this.$router.resolve({
                path: `/products/${product_id}`,
            });
            window.open(href);
        },
        setCartList(){
            storage.setLocalData('cart_data', JSON.stringify(this.cartList))
        },
        changeNums(index, nums){
            this.cartList[index].nums = nums
            this.setCartList()
        },
        loadLocalCartData(){
            const cartList = storage.getLocalData('cart_data')
            if(cartList){
                this.cartList = JSON.parse(cartList)
            }
        }
    },
    computed: {
        totalPrice(){
           if(this.cartList.length == 0) return 0
           let total = 0
           this.cartList.forEach((product, idx) => {
               total += Number(product.nums) * Number(product.product_price)
           })
           return total
        }
    },
    created(){
        this.getProductClassListHandler()
        this.loadLocalCartData()
    }
}
</script>
<style lang="stylus" scoped>
.goods_list
    padding 10px
    border-right 1px solid #eee
.cart_list
    padding 10px
.cart_bottom
    padding 0 20px
.cart_top
    display flex
    justify-content space-between
    align-items center
    .cart_title
       font-size 18px
    .cart_price
       color #abb1bf
       strong
           color #43af52
           font-size 18px
.goods_main
    overflow auto
.goods_bottom
   display flex
   justify-content space-between
   align-items center
.card
   padding 10px
   border-radius 10px
   box-shadow 0 0 10px 10px rgba(0,0,0,.05)
   width 200px
   background #fff
   .brand
        .brand_title
            font-size 18px
            margin-bottom 10px
        .brand_list
            width 100%
            height calc(100% - 40px)
            overflow auto
            overflow-x hidden
            .brand_cell
                margin-bottom 10px
                cursor pointer
                .brand_logo
                    width 60%
                    border 1px solid #fff
                    margin auto
                    display block
                    transition .5s
                    &.on
                        border 1px solid #43af52
                    &:hover
                        border 1px solid #43af52
.product_cell
    background #fff
    padding 10px
    border-radius 10px
    box-shadow 0 10px 10px 0 rgba(0,0,0,.05)
    margin-bottom 10px
    display flex
    flex-shrink 1
    cursor pointer
    .product_image
        width 100px
        height 130px
        margin 0 20px
        .image
            width 100%
            height 130px
            object-fit cover
    .product_info
        width 100%
        .product_name
            font-size 16px
        .product_top
            justify-content space-between
            align-items center
            .product_price
                font-size 22px
                color #43af52
        .product_description
            color #abb1bf
            font-size 14px
        .btn
            width 120px
.cart_data
    .product_cell
        border 1px dashed #eee
        box-shadow none
    .product_name
        font-size 14px !important
        white-space nowrap
        text-overflow ellipsis
        overflow hidden
    .product_image
        width 40px
        height 80px
        margin 0 10px
        .image
            width 100%
            height 80px
            object-fit cover
    .product_top:last-child
        margin-top 10px
.ps
    height 100px
</style>
<style lang="stylus">
// .product_description h1,h2,h3,h4,h5,h6
// .product_description span
//     white-space nowrap
//     overflow hidden
//     text-overflow ellipsis  
//     display block
</style>